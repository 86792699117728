import { KeyboardArrowDown, KeyboardArrowUp, ReportGmailerrorred, TaskAlt } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Fragment, ReactElement, useState } from 'react';

import { countryLib, countryModel } from 'entities/country';
import { diseaseLib } from 'entities/disease';

import { Disease, EDiseaseStatus, Vaccine } from 'shared/api';
import { ProgressHelp } from 'shared/assets/svg';

import { formatedPetsTypeName } from '../../lib';

type Props = {
  list: Disease[];
  isPets?: boolean;
  editRow: (props: any) => ReactElement;
  onUpdate: Function;
  isSuccessUpdate: boolean;
  isLoadingUpdate: boolean;
  vaccineList: Vaccine[];
  diseaseList: Disease[];
};
export const DiseaseTable = ({
  editRow,
  list,
  onUpdate,
  isSuccessUpdate,
  isLoadingUpdate,
  vaccineList,
  diseaseList,
  isPets = false,
}: Props) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 750 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">
              <ProgressHelp />
            </TableCell>
            <TableCell>Disease</TableCell>
            {isPets && <TableCell>Type</TableCell>}
            <TableCell>Countries</TableCell>
            <TableCell>In the national vaccination calendar of countries</TableCell>
            <TableCell width={460}>Vaccines</TableCell>
            <TableCell>Сontrols</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...list]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((row) => (
              <Row
                key={row._id}
                row={row}
                isPets={isPets}
                editRow={editRow}
                onUpdate={onUpdate}
                isSuccessUpdate={isSuccessUpdate}
                isLoadingUpdate={isLoadingUpdate}
                vaccineList={vaccineList}
                diseaseList={diseaseList}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type RowProps = {
  row: Disease;
  isPets?: boolean;
  editRow: (props: any) => ReactElement;
  onUpdate: Function;
  isSuccessUpdate: boolean;
  isLoadingUpdate: boolean;
  vaccineList: Vaccine[];
  diseaseList: Disease[];
};

const Row = ({
  row,
  editRow: EditRow,
  onUpdate,
  isSuccessUpdate,
  isLoadingUpdate,
  vaccineList,
  diseaseList,
  isPets = false,
}: RowProps) => {
  const { list: countryList } = countryModel.useCountries();
  const COUNT_VACCINES = 8;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} sx={{ color: '#29b194' }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          {row.verified ? <TaskAlt color="success" /> : <ReportGmailerrorred color="warning" />}
        </TableCell>
        <TableCell component="th" scope="row" sx={{ verticalAlign: 'top' }}>
          <Box mb={1}>{row.name}</Box>
          <Box color="rgba(116, 116, 116, 1)">{row.code}</Box>
        </TableCell>
        {isPets && <TableCell sx={{ verticalAlign: 'top' }}>{formatedPetsTypeName(row.type_pets)}</TableCell>}
        <TableCell sx={{ verticalAlign: 'top' }}>
          {
            countryLib.getCountriesListNamesByCode(
              countryList,
              row.countries.map(({ countryCode: code }) => code),
            ).length
          }
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Box display="flex" flexWrap="wrap">
            {countryLib
              .getCountriesListNamesByCode(
                countryList,
                row.countries
                  .filter((item) => item.status === EDiseaseStatus.Mandatory)
                  .map((item) => item.countryCode),
              )
              .map((country) => (
                <Box
                  key={country}
                  component="span"
                  p={0.5}
                  m={0.25}
                  sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: '4px', whiteSpace: 'nowrap' }}
                >
                  {country}
                </Box>
              ))}
          </Box>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Box display="flex" flexWrap="wrap">
            {row.vaccine_list.map((vaccine, index) => {
              const foundedVaccine = vaccineList.find(({ _id }) => _id === vaccine);

              if (!foundedVaccine) return null;
              if (index > COUNT_VACCINES) return null;

              return (
                <Tooltip key={foundedVaccine.name} title={foundedVaccine.name}>
                  <Box
                    component="span"
                    p={0.5}
                    m={0.25}
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      borderRadius: '4px',
                      color: foundedVaccine.verified ? '#29b194' : 'inherit',
                    }}
                  >
                    {diseaseLib.formatedName(foundedVaccine.name)}
                  </Box>
                </Tooltip>
              );
            })}
            {row.vaccine_list.length > COUNT_VACCINES && (
              <Box
                component="span"
                p={0.5}
                m={0.25}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: '4px' }}
              >
                + {row.vaccine_list.length - COUNT_VACCINES}
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <EditRow
            disease={row}
            isPets={isPets}
            onUpdate={onUpdate}
            isSuccessUpdate={isSuccessUpdate}
            isLoadingUpdate={isLoadingUpdate}
            vaccineList={vaccineList}
            diseaseList={diseaseList}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && (
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Descriptions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <Box px={2} my={0.5}>
                        {row.descriptions?.trim().length ? row.descriptions : 'Empty'}
                      </Box>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
