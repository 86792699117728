import { Country } from 'shared/api';

export const getCountriesNamesByCode = (countries: Country[], countryCode: string[]) => {
  return countryCode.reduce((acc, item, index) => {
    acc += `${countries.find(({ code }) => code === item)?.name ?? ''}${countryCode.length - 1 !== index ? ', ' : ''}`;
    return acc;
  }, '');
};

export const getCountriesListNamesByCode = (countries: Country[], countryCode: string[]) => {
  return countryCode.reduce<string[]>((acc, item, index) => {
    const country = countries.find(({ code }) => code === item)?.name;

    return country ? (acc = [...acc, country]) : acc;
  }, []);
};
