import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { countryModel } from 'entities/country';
import { userModel } from 'entities/user';
import { VaccineDialog, vaccineModel } from 'entities/vaccine';

type Props = {
  disabled?: boolean;
  onSubmit: Function;
  isSuccess: boolean;
  isLoading: boolean;
};

export const CreateVaccine = ({ disabled = false, onSubmit, isSuccess, isLoading }: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { list: vaccineList } = vaccineModel.useVaccines();
  const { list } = countryModel.useCountries();
  const { data } = userModel.useUser();

  useEffect(() => {
    if (isSuccess) setIsOpen(false);
  }, [isSuccess]);

  return (
    <>
      <Button variant="contained" onClick={() => setIsOpen(true)} sx={{ alignSelf: 'end' }} disabled={disabled}>
        Add vaccine
      </Button>
      <VaccineDialog
        title="Add vaccine"
        isOpen={isOpen}
        vaccineList={vaccineList}
        userRole={data?.role}
        onClose={() => setIsOpen(false)}
        onSubmit={onSubmit}
        isLoading={isLoading}
        countries={list}
      />
    </>
  );
};
