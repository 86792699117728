import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Country, typicodeApi } from 'shared/api';

type TCountryState = {
  list: Country[];
};

const initialState: TCountryState = {
  list: [],
};

export const countryModel = createSlice({
  name: 'сountries',
  initialState,
  reducers: {
    setCountries: (state, { payload }: PayloadAction<Country[]>) => {
      state.list = payload;
    },
  },
});

// react-query actions (everything that async)
const ACTS_QUERY_KEY = 'countries';

export const getCountriesAsync = () => (dispatch: Dispatch) =>
  useQuery<AxiosResponse<Country[]>>(ACTS_QUERY_KEY, () => typicodeApi.country.getCountries(), {
    onSuccess: ({ data }) => {
      dispatch(countryModel.actions.setCountries(data));
    },
    onError: () => {
      toast.error('There was an error loading the list of countries, please try reloading the page!');
    },
    refetchOnWindowFocus: false,
  });

// selectors

export const useCountries = () => useSelector((state: RootState) => state.countries);

export const reducer = countryModel.reducer;
