import { KeyboardArrowDown, KeyboardArrowUp, Verified } from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { Country, Disease, EDiseaseStatus } from 'shared/api';

type Props = {
  list: Country[];
  listDisease: Disease[];
};
export const CountriesTable = ({ list, listDisease }: Props) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 750 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Country</TableCell>
            <TableCell>Basic</TableCell>
            <TableCell>Mandatory</TableCell>
            <TableCell>Additional</TableCell>
            <TableCell>Epidemic</TableCell>
            <TableCell>Not distributed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...list]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((row) => (
              <Row key={row.code} row={row} listDisease={listDisease} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type RowProps = {
  row: Country;
  listDisease: Disease[];
};
type TGrouped = Record<EDiseaseStatus, Array<{ name: string; verified: boolean }>>;
// [...new Set([...prev[currentCountry.status], disease.name])],
const Row = ({ row, listDisease }: RowProps) => {
  const [open, setOpen] = useState(false);
  const [groupedDisease, setGroupedDisease] = useState<TGrouped>({
    basic: [],
    mandatory: [],
    additional: [],
    epidemic: [],
  });

  useEffect(() => {
    listDisease?.forEach((disease) => {
      const currentCountry = disease.countries.find((country) => row.code === country.countryCode);
      if (currentCountry)
        setGroupedDisease((prev) => {
          const prevState = prev[currentCountry.status];
          const nextState = [...prev[currentCountry.status], { name: disease.name, verified: disease.verified }];

          if (prevState.some((item) => item.name === disease.name)) {
            return prev;
          }

          return {
            ...prev,
            [currentCountry.status]: nextState,
          };
        });
    });
  }, []);

  const getNotDistributed = useCallback(() => {
    return [...listDisease]
      .filter(
        (disease) =>
          !groupedDisease.basic.some(({ name }) => name === disease.name) &&
          !groupedDisease.mandatory.some(({ name }) => name === disease.name) &&
          !groupedDisease.additional.some(({ name }) => name === disease.name) &&
          !groupedDisease.epidemic.some(({ name }) => name === disease.name),
      )
      .map((d) => d.name);
  }, [groupedDisease]);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={row.code}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} sx={{ color: '#29b194' }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', verticalAlign: 'top' }}>
            {groupedDisease.basic.map((item) => (
              <Box
                key={`${row.code}_${item.name}_basic`}
                component="span"
                p={0.5}
                m={0.25}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                  color: item.verified ? '#29b194' : 'inherit',
                }}
              >
                {item.name}
              </Box>
            ))}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', verticalAlign: 'top' }}>
            {groupedDisease.mandatory.map((item) => (
              <Box
                key={`${row.code}_${item.name}_mandatory`}
                component="span"
                p={0.5}
                m={0.25}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                  color: item.verified ? '#29b194' : 'inherit',
                }}
              >
                {item.name}
              </Box>
            ))}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', verticalAlign: 'top' }}>
            {groupedDisease.additional.map((item) => (
              <Box
                key={`${row.code}_${item.name}_additional`}
                component="span"
                p={0.5}
                m={0.25}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                  color: item.verified ? '#29b194' : 'inherit',
                }}
              >
                {item.name}
              </Box>
            ))}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', verticalAlign: 'top' }}>
            {groupedDisease.epidemic.map((item) => (
              <Box
                key={`${row.code}_${item.name}_epidemic`}
                component="span"
                p={0.5}
                m={0.25}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  borderRadius: '4px',
                  color: item.verified ? '#29b194' : 'inherit',
                }}
              >
                {item.name}
              </Box>
            ))}
          </Box>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Box component="span" p={0.5} m={0.25} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: '4px' }}>
            {getNotDistributed().length}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && (
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Not distributed disease</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <Box px={2} my={0.5} display="flex" flexWrap={'wrap'}>
                        {getNotDistributed().map((name) => {
                          return (
                            <Tooltip key={name} title={name}>
                              <Box
                                component="span"
                                p={0.5}
                                m={0.25}
                                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: '4px' }}
                              >
                                {name}
                              </Box>
                            </Tooltip>
                          );
                        })}
                      </Box>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
