import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UsersTable, userModel } from 'entities/user';

import { routesApp } from 'shared/config';
import { LoadingProgress } from 'shared/ui';

export const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching } = userModel.getUsersAsync()(dispatch);
  const { userList } = userModel.useUser();

  const handleOpenUser = (userId: string) => navigate(`${routesApp.users}/${userId}`);

  if (isFetching) return <LoadingProgress />;

  return (
    <Box display="flex" flexDirection="column" flex={1} px={3}>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        Список пользователей
      </Typography>
      <UsersTable users={userList} openUser={handleOpenUser} />
    </Box>
  );
};
