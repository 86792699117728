import { Box } from '@mui/material';

import { DiseaseTab } from 'widgets/disease';
import { VaccineTab } from 'widgets/vaccine';

import { CountriesTab } from 'features/countries';

import { NavTabs } from 'shared/ui';

const NAV_TABS = [
  {
    name: 'Vaccines',
    Content: () => <VaccineTab />,
  },
  {
    name: 'Diseases',
    Content: () => <DiseaseTab />,
  },
  {
    name: 'Countries',
    Content: () => <CountriesTab />,
  },
];

export const Main = () => {
  return (
    <Box display="flex" flexDirection="column" flex={1} px={3}>
      <NavTabs tabs={NAV_TABS} />
    </Box>
  );
};
