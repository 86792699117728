import { AxiosPromise } from 'axios';

import { EUserRole, User, UserResponse } from 'shared/api';
import { apiInstance } from 'shared/api/typicode/base';

const BASE_URL_AUTH = '/auth';
const BASE_URL_USER = '/user';

/**
 * Получение кода для входа в админку
 */
export const authSendEmail = async (data: { email: string }): AxiosPromise<void> =>
  apiInstance.post(`${BASE_URL_AUTH}/getCode`, data);

/**
 * Отправка кода для входа в админку
 */
export const authSendCode = async (data: { code: string; email: string }): AxiosPromise<User> =>
  apiInstance.post(`${BASE_URL_AUTH}/signin`, data);

/**
 * Получение данные о пользователе
 */
export const getUser = async (): AxiosPromise<User> =>
  apiInstance.get(`${BASE_URL_USER}`, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });

/**
 * Получение данные об учетке по id
 */
export const getUserById = async (id: string): AxiosPromise<UserResponse> =>
  apiInstance.get(`${BASE_URL_USER}/${id}`, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });

/**
 * Получения данных обо всех учетках
 */
export const getUsers = async (): AxiosPromise<UserResponse[]> =>
  apiInstance.get(`${BASE_URL_USER}/all`, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });

/**
 * Обновление роли пользователя
 */
export const updateUserById = async (data: { role: EUserRole; _id: string }): AxiosPromise<UserResponse> => {
  const { _id, ...datas } = data;
  return apiInstance.patch(`${BASE_URL_USER}/${_id}`, datas, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });
};
