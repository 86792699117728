import { ETypePets } from 'shared/api';

export const formatedName = (filename: string, sizeLength: number = 20): string => {
  if (filename.length > sizeLength) {
    const startName = filename.slice(0, 19);

    return `${startName}...`;
  }

  return filename;
};

export const formatedPetsTypeName = (type?: ETypePets) => {
  if (!type) return '';

  if (type === ETypePets.Cat) {
    return `Cat`;
  }

  if (type === ETypePets.Dog) {
    return `Dog`;
  }

  return 'Dog, Cat';
};
