import { Add, ReportGmailerrorred, TaskAlt } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { AutocompleteCheckboxesCountry } from 'entities/country';

import {
  Country,
  ENextRound,
  ENextRoundVariant,
  ETypeVaccine,
  EUserRole,
  EVaccineType,
  TVaccineRound,
  Vaccine,
} from 'shared/api';
import { AutocompleteCountry, SelectInput } from 'shared/ui';

const validationSchema = yup.object({
  name: yup.string().required('Обязательное поле'),
  manufacturerCountry: yup.string().required('Обязательное поле'),
  manufacturer: yup.string().required('Обязательное поле'),
  availableCountries: yup.array().of(yup.string()),
  age_shedule: yup.array().of(
    yup.object().shape({
      start: yup.string().required('Обязательное поле'),
      end: yup.string().required('Обязательное поле'),
      rounds: yup
        .array()
        .min(1, 'Добавьте хотя бы один раунд')
        .of(
          yup.object().shape({
            type_round: yup
              .mixed<ENextRoundVariant>()
              .oneOf(Object.values(ENextRoundVariant))
              .required('Обязательное поле'),
            count_days: yup.number().notRequired(),
            count_days2: yup.number().notRequired(),
            count_years: yup.number().notRequired(),
            type_vaccination: yup
              .mixed<ETypeVaccine>()
              .oneOf(Object.values(ETypeVaccine))
              .required('Обязательное поле'),
          }),
        ),
    }),
  ),
  verified: yup.boolean(),
  descriptions: yup.string(),
  descriptions_en: yup.string(),
  notes: yup.string(),
});

const initialRound: TVaccineRound = {
  type_round: ENextRoundVariant.AnyDays,
  count_days: undefined,
  count_days2: undefined,
  count_years: undefined,
  type_vaccination: ETypeVaccine.Vaccination,
  isPeriodicRevactination: false,
};

const initialValues: Omit<Vaccine, '_id'> = {
  name: '',
  name_ru: '',
  manufacturerCountry: '',
  manufacturer: '',
  availableCountries: [],
  vaccine_type: EVaccineType.alive,
  age_shedule: [],
  verified: false,
  descriptions: undefined as unknown as string,
  descriptions_en: undefined as unknown as string,

  pharmacological_properties: undefined as unknown as string,
  compound: undefined as unknown as string,
  indications_for_use: undefined as unknown as string,
  contraindications: undefined as unknown as string,
  mode_of_application: undefined as unknown as string,
  precautions_for_use: undefined as unknown as string,
  carefully: undefined as unknown as string,
  special_instructions: undefined as unknown as string,
  side_effect: undefined as unknown as string,
  use_during_pregnancy_and_breastfeeding: undefined as unknown as string,
  interaction_with_other_drugs: undefined as unknown as string,
  storage_conditions: undefined as unknown as string,
  transportation_conditions: undefined as unknown as string,

  notes: undefined as unknown as string,
};

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: Function;
  onVerified?: Function;
  isLoading: boolean;
  userRole?: EUserRole;
  selectedVaccine?: Vaccine;
  vaccineList: Vaccine[];
  countries: Country[];
};

export const VaccineDialog = ({
  title,
  isOpen,
  onClose,
  onSubmit,
  onVerified,
  userRole,
  isLoading,
  selectedVaccine,
  vaccineList,
  countries,
}: Props) => {
  const [selectedAgeShedule, setSelectedAgeShedule] = useState(0);
  const [selectedRound, setSelectedRound] = useState(0);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: Omit<Vaccine, '_id'>) => {
      const newShedules = values.age_shedule.map((item) => {
        const newRounds = item.rounds.map((round) => {
          if (round.type_vaccination === ETypeVaccine.ReVaccination && round.isPeriodicRevactination) {
            return {
              ...round,
              count_days: undefined,
              count_days2: undefined,
            };
          }

          if (round.type_vaccination === ETypeVaccine.Vaccination) {
            return {
              ...round,
              count_years: undefined,
              isPeriodicRevactination: false,
            };
          }

          return round;
        });

        return { ...item, rounds: newRounds };
      });

      onSubmit({ ...values, age_shedule: newShedules, verified: false });
    },
  });

  const isExistVaccine =
    !!vaccineList.find(
      ({ name, manufacturer, manufacturerCountry }) =>
        name === formik.values.name &&
        manufacturer === formik.values.manufacturer &&
        manufacturerCountry === formik.values.manufacturerCountry,
    ) &&
    selectedVaccine?.name !== formik.values.name &&
    selectedVaccine?.manufacturer !== formik.values.manufacturer &&
    selectedVaccine?.manufacturerCountry !== formik.values.manufacturerCountry;

  const handleAddAgeShedule = () => {
    const lastPeriod = formik.values.age_shedule.at(-1);
    void formik.setFieldValue('age_shedule', [
      ...formik.values.age_shedule,
      {
        start: lastPeriod?.end ? +lastPeriod?.end + 1 : '0',
        end: lastPeriod?.end ? +lastPeriod?.end + 2 : '36500',
        rounds: [initialRound],
      },
    ]);

    setSelectedAgeShedule(formik.values.age_shedule.length);
  };

  const handleAddRound = () => {
    const newAgeShedules = formik.values.age_shedule.map((item, index) => {
      if (index === selectedAgeShedule) {
        return { ...item, rounds: [...item.rounds, initialRound] };
      }

      return item;
    });

    void formik.setFieldValue('age_shedule', newAgeShedules);

    setSelectedRound(formik.values.age_shedule[selectedAgeShedule]?.rounds.length);
  };

  const handleDeleteRound = () => {
    const newAgeShedules = formik.values.age_shedule.map((item, index) => {
      if (index === selectedAgeShedule) {
        return { ...item, rounds: item.rounds.slice(0, -1) };
      }

      return item;
    });

    void formik.setFieldValue('age_shedule', newAgeShedules);
  };

  const handleDeleteAgeShedule = () => {
    setSelectedAgeShedule((prev) => --prev);
    void formik.setFieldValue(
      'age_shedule',
      formik.values.age_shedule.filter((_, index) => index !== selectedAgeShedule),
    );
  };

  const handleChangeCountry = (_: any, v: any) => formik.setFieldValue('manufacturerCountry', v?.name ?? '');

  useEffect(() => {
    if (!isOpen) void formik.setValues(initialValues);
    if (selectedVaccine) void formik.setValues(selectedVaccine);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && formik.values.age_shedule.length === 0) {
      handleAddAgeShedule();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && formik.values.age_shedule[0]?.rounds.length === 0) {
      handleAddRound();
    }
  }, [formik.values.age_shedule.length]);

  useEffect(() => setSelectedRound(0), [selectedAgeShedule]);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <form onSubmit={formik.handleSubmit} style={{ paddingRight: '8px' }}>
        <DialogTitle>
          {title}
          <Box display="flex" alignItems="center">
            <Typography sx={{ color: formik.values.verified ? '#2e7d32' : '#ed6c02' }}>
              {formik.values.verified ? 'Verified' : 'Not verified'}
            </Typography>
            {formik.values.verified ? <TaskAlt color="success" /> : <ReportGmailerrorred color="warning" />}
          </Box>
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Name (EN)"
            fullWidth
            multiline
            margin="dense"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={isExistVaccine}
            helperText={isExistVaccine && 'Вакцина с таким названием уже добавлена!'}
          />
          <TextField
            label="Name (RU)"
            fullWidth
            multiline
            margin="dense"
            name="name_ru"
            value={formik.values.name_ru}
            onChange={formik.handleChange}
            error={isExistVaccine}
            helperText={isExistVaccine && 'Вакцина с таким названием уже добавлена!'}
          />
          <TextField
            label="Manufacturing"
            fullWidth
            margin="dense"
            name="manufacturer"
            value={formik.values.manufacturer}
            onChange={formik.handleChange}
            error={isExistVaccine}
            helperText={isExistVaccine && 'Вакцина с таким производителем уже добавлена!'}
          />
          <AutocompleteCountry
            label="Manufacturer country"
            value={formik.values.manufacturerCountry}
            countries={countries}
            onChange={handleChangeCountry}
            error={isExistVaccine}
            helperText={isExistVaccine && 'Вакцина с такой страной производителем уже добавлена!'}
          />
          <AutocompleteCheckboxesCountry
            label="Certified in Countries"
            multiple={true}
            name="availableCountries"
            options={countries}
            values={formik.values.availableCountries}
            onChange={formik.setFieldValue}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Type of vaccine</InputLabel>
            <Select
              label="Type of vaccine"
              value={formik.values.vaccine_type}
              name={`vaccine_type`}
              onChange={formik.handleChange}
            >
              {Object.entries(EVaccineType).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ backgroundColor: 'rgba(41,177,148, 0.15)' }} p={2}>
            <Typography fontWeight="bold">Age Shedule</Typography>
            {formik.values.age_shedule.map((item, index) => (
              <Chip
                key={`${index} + ${item.start}age_shedule`}
                label={`${item.start}-${item.end}`}
                onClick={() => setSelectedAgeShedule(index)}
                sx={{
                  backgroundColor: selectedAgeShedule === index ? '#29b194' : 'unset',
                  color: selectedAgeShedule === index ? 'white' : '',
                  borderRadius: '6px',
                  border: selectedAgeShedule !== index ? '1px solid rgba(0, 0, 0, 0.3)' : '',
                  margin: '0 2px',
                }}
                clickable={selectedAgeShedule !== index}
              />
            ))}
            <Button
              variant="contained"
              size="small"
              sx={{
                ml: 1,
                px: 0.5,
                minWidth: 'auto',
                color: 'rgba(58, 58, 58, 1)',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.07)' },
              }}
              onClick={handleAddAgeShedule}
            >
              <Add />
            </Button>
            {formik.values.age_shedule.length > 0 && (
              <Box mb={2}>
                <Box display="flex" justifyContent="space-between">
                  <TextField
                    sx={{ width: '49%' }}
                    margin="dense"
                    label="Start (in days)*"
                    type="number"
                    name={`age_shedule[${selectedAgeShedule}].start`}
                    value={formik.values.age_shedule[selectedAgeShedule]?.start}
                    onChange={formik.handleChange}
                    error={`${formik.values.age_shedule[selectedAgeShedule]?.start}`.length === 0}
                    helperText={
                      `${formik.values.age_shedule[selectedAgeShedule]?.start}`.length === 0 && 'Field is required!'
                    }
                  />
                  <TextField
                    sx={{ width: '49%' }}
                    margin="dense"
                    label="End (in days)*"
                    type="number"
                    name={`age_shedule[${selectedAgeShedule}].end`}
                    value={formik.values.age_shedule[selectedAgeShedule]?.end}
                    onChange={formik.handleChange}
                    error={`${formik.values.age_shedule[selectedAgeShedule]?.end}`.length === 0}
                    helperText={
                      `${formik.values.age_shedule[selectedAgeShedule]?.end}`.length === 0 && 'Field is required!'
                    }
                  />
                </Box>
                {selectedAgeShedule !== 0 && (
                  <Box display="flex" justifyContent="flex-end">
                    <Button color="error" size="small" onClick={handleDeleteAgeShedule}>
                      DELETE THIS AGE
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {formik.values.age_shedule[selectedAgeShedule]?.rounds.map((round, index) => (
              <Chip
                key={index}
                label={`Round ${index + 1}`}
                onClick={() => setSelectedRound(index)}
                sx={{
                  backgroundColor: selectedRound === index ? '#29b194' : 'unset',
                  color: selectedRound === index ? 'white' : '',
                  borderRadius: '6px',
                  border: selectedRound !== index ? '1px solid rgba(0, 0, 0, 0.3)' : '',
                  margin: '0 2px',
                }}
                clickable={selectedRound !== index}
              />
            ))}
            {!(
              formik.values.age_shedule.at(-1)?.rounds.at(-1)?.type_vaccination === ETypeVaccine.ReVaccination &&
              formik.values.age_shedule.at(-1)?.rounds.at(-1)?.isPeriodicRevactination
            ) && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  ml: 1,
                  px: 0.5,
                  minWidth: 'auto',
                  color: 'rgba(58, 58, 58, 1)',
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.07)' },
                }}
                onClick={handleAddRound}
              >
                <Add />
              </Button>
            )}
            {formik.values.age_shedule[selectedAgeShedule]?.rounds[selectedRound] &&
              [formik.values.age_shedule[selectedAgeShedule]?.rounds[selectedRound]].map((round) => (
                <Box key={`${selectedAgeShedule}_${selectedRound}_age_shedule_rounds`} mt={2}>
                  <RadioGroup
                    row
                    aria-labelledby="type_vaccination"
                    name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].type_vaccination`}
                    value={formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.type_vaccination}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel value={ETypeVaccine.Vaccination} control={<Radio />} label="Vaccination" />
                    <FormControlLabel value={ETypeVaccine.ReVaccination} control={<Radio />} label="Revaccination" />
                  </RadioGroup>
                  {formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.type_vaccination ===
                    ETypeVaccine.ReVaccination && (
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Periodically"
                      name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].isPeriodicRevactination`}
                      checked={
                        formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.isPeriodicRevactination
                      }
                      onChange={formik.handleChange}
                    />
                  )}
                  <SelectInput
                    label="Type round (date vaccination)"
                    name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].type_round`}
                    value={formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.type_round}
                    menuItem={Object.entries(ENextRound)
                      .filter((_, index) => {
                        if (selectedRound === 0 && index < 2) return true;
                        if (selectedRound === 1 && index > 0 && index < 3) return true;
                        if (selectedRound > 1 && index > 0) return true;

                        return false;
                      })
                      .map(([value, label]) => ({ label, value }))}
                    onChange={formik.setFieldValue}
                  />
                  {(formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.type_vaccination ===
                    ETypeVaccine.Vaccination ||
                    (formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.type_vaccination ===
                      ETypeVaccine.ReVaccination &&
                      !formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.isPeriodicRevactination)) &&
                    formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.type_round !==
                      ENextRoundVariant.AnyDays && (
                      <Box display="flex" justifyContent="space-between">
                        <TextField
                          sx={{ width: '49%' }}
                          margin="dense"
                          label="Start (in days)"
                          type="number"
                          name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].count_days`}
                          value={formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.count_days}
                          onChange={formik.handleChange}
                        />
                        <TextField
                          sx={{ width: '49%' }}
                          margin="dense"
                          label="End (in days)"
                          type="number"
                          name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].count_days2`}
                          value={formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.count_days2}
                          onChange={formik.handleChange}
                        />
                      </Box>
                    )}
                  {formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.type_vaccination ===
                    ETypeVaccine.ReVaccination &&
                    formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.isPeriodicRevactination && (
                      <FormControl fullWidth margin="dense">
                        <InputLabel>Period</InputLabel>
                        <Select
                          label="Age"
                          value={formik.values.age_shedule[selectedAgeShedule].rounds[selectedRound]?.count_years}
                          name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].count_years`}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value={1}>Every year</MenuItem>
                          <MenuItem value={2}>Once every 2 years</MenuItem>
                          <MenuItem value={3}>Once every 3 years</MenuItem>
                          <MenuItem value={5}>Once every 5 years</MenuItem>
                          <MenuItem value={10}>Once every 10 years</MenuItem>
                          <MenuItem value={15}>Once every 15 years</MenuItem>
                          <MenuItem value={20}>Once every 20 years</MenuItem>
                          <MenuItem value={25}>Once every 25 years</MenuItem>
                          <MenuItem value={30}>Once every 30 years</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  {selectedRound !== 0 && (
                    <Box display="flex" justifyContent="flex-end">
                      <Button color="error" size="small" onClick={handleDeleteRound}>
                        DELETE THIS ROUND
                      </Button>
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
          <TextField
            label="Descriptions (EN)"
            fullWidth
            sx={{ mt: 4 }}
            margin="dense"
            multiline
            name="descriptions_en"
            value={formik.values.descriptions_en ?? selectedVaccine?.descriptions_en}
            onChange={formik.handleChange}
          />
          <TextField
            label="Descriptions (RU)"
            fullWidth
            margin="dense"
            multiline
            name="descriptions"
            value={formik.values.descriptions ?? selectedVaccine?.descriptions}
            onChange={formik.handleChange}
          />
          <TextField
            label="Pharmacological properties"
            fullWidth
            margin="dense"
            multiline
            name="pharmacological_properties"
            value={formik.values.pharmacological_properties ?? selectedVaccine?.pharmacological_properties}
            onChange={formik.handleChange}
          />
          <TextField
            label="Compound"
            fullWidth
            margin="dense"
            multiline
            name="compound"
            value={formik.values.compound ?? selectedVaccine?.compound}
            onChange={formik.handleChange}
          />
          <TextField
            label="Indications for use"
            fullWidth
            margin="dense"
            multiline
            name="indications_for_use"
            value={formik.values.indications_for_use ?? selectedVaccine?.indications_for_use}
            onChange={formik.handleChange}
          />
          <TextField
            label="Contraindications"
            fullWidth
            margin="dense"
            multiline
            name="contraindications"
            value={formik.values.contraindications ?? selectedVaccine?.contraindications}
            onChange={formik.handleChange}
          />
          <TextField
            label="Mode of application"
            fullWidth
            margin="dense"
            multiline
            name="mode_of_application"
            value={formik.values.mode_of_application ?? selectedVaccine?.mode_of_application}
            onChange={formik.handleChange}
          />
          <TextField
            label="Precautions for use"
            fullWidth
            margin="dense"
            multiline
            name="precautions_for_use"
            value={formik.values.precautions_for_use ?? selectedVaccine?.precautions_for_use}
            onChange={formik.handleChange}
          />
          <TextField
            label="Carefully"
            fullWidth
            margin="dense"
            multiline
            name="carefully"
            value={formik.values.carefully ?? selectedVaccine?.carefully}
            onChange={formik.handleChange}
          />
          <TextField
            label="Special instructions"
            fullWidth
            margin="dense"
            multiline
            name="special_instructions"
            value={formik.values.special_instructions ?? selectedVaccine?.special_instructions}
            onChange={formik.handleChange}
          />
          <TextField
            label="Side effect"
            fullWidth
            margin="dense"
            multiline
            name="side_effect"
            value={formik.values.side_effect ?? selectedVaccine?.side_effect}
            onChange={formik.handleChange}
          />
          <TextField
            label="Use during pregnancy and breastfeeding"
            fullWidth
            margin="dense"
            multiline
            name="use_during_pregnancy_and_breastfeeding"
            value={
              formik.values.use_during_pregnancy_and_breastfeeding ??
              selectedVaccine?.use_during_pregnancy_and_breastfeeding
            }
            onChange={formik.handleChange}
          />
          <TextField
            label="Interaction with other drugs"
            fullWidth
            margin="dense"
            multiline
            name="interaction_with_other_drugs"
            value={formik.values.interaction_with_other_drugs ?? selectedVaccine?.interaction_with_other_drugs}
            onChange={formik.handleChange}
          />
          <TextField
            label="Storage conditions"
            fullWidth
            margin="dense"
            multiline
            name="storage_conditions"
            value={formik.values.storage_conditions ?? selectedVaccine?.storage_conditions}
            onChange={formik.handleChange}
          />
          <TextField
            label="Transportation conditions"
            fullWidth
            margin="dense"
            multiline
            name="transportation_conditions"
            value={formik.values.transportation_conditions ?? selectedVaccine?.transportation_conditions}
            onChange={formik.handleChange}
          />
          <TextField
            label="Notes"
            fullWidth
            margin="dense"
            multiline
            name="notes"
            value={formik.values.notes ?? selectedVaccine?.notes}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions sx={{ mx: 2 }}>
          {userRole === EUserRole.Admin && onVerified && (
            <Button
              onClick={() => {
                onVerified({ ...formik.values, verified: !formik.values.verified });
                onClose();
              }}
              variant="contained"
              color={formik.values.verified ? 'error' : 'primary'}
              sx={{ mr: 'auto' }}
            >
              {formik.values.verified ? 'Unverified' : 'Save and Verified'}
            </Button>
          )}
          <Button onClick={onClose} sx={{ mr: 8 }}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isExistVaccine || !formik.isValid}
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
