import { AxiosPromise } from 'axios';

import { Vaccine } from 'shared/api';
import { apiInstance } from 'shared/api/typicode/base';

const BASE_URL = '/vaccine';

/**
 * Получение данных о добавленных вакцинах
 */
export const getVaccines = async (): AxiosPromise<Vaccine[]> =>
  apiInstance.get(`${BASE_URL}`, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });

/**
 * Добавление новой вакцины
 */
export const postVaccine = async (data: Omit<Vaccine, '_id'>): AxiosPromise<Vaccine> =>
  apiInstance.post(`${BASE_URL}`, data, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });

/**
 * Обновление вакцины
 */
export const patchVaccine = async (data: Vaccine): AxiosPromise<Vaccine> => {
  const { _id, ...datas } = data;

  return apiInstance.patch(
    `${BASE_URL}/${_id}`,
    { ...datas },
    {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
      },
    },
  );
};
