import LogoutIcon from '@mui/icons-material/Logout';
import { Button, IconButton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { CSSProperties, forwardRef } from 'react';
import { NavLink as NavLinkBase, NavLinkProps, useLocation, useNavigate } from 'react-router-dom';

import { userLib, userModel } from 'entities/user';

import { Icons } from 'shared/assets';
import { routesApp } from 'shared/config';

const PAGES = [
  { label: 'Peoples', path: routesApp.peoples, icon: Icons.People, disabled: false },
  { label: 'Pets', path: routesApp.pets, icon: Icons.Pet, disabled: false },
  { label: 'Users', path: routesApp.users, icon: Icons.Users, disabled: false },
  { label: 'Pharma companies', path: routesApp.pharmaCompanies, icon: Icons.PharmaCompanies, disabled: true },
  { label: 'Analytics', path: routesApp.analytics, icon: Icons.Analytics, disabled: true },
];

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user } = userModel.useUser();

  const handleLogout = () => {
    window.localStorage.removeItem('token');
    navigate(routesApp.sendEmail);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'white', boxShadow: '0 2px 15px 0 rgba(0, 0, 0, .05)' }}>
      <Toolbar>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: '#29b194' }}>
          VaccineGo
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {PAGES.map(({ label, path, icon: Icon, disabled }) => (
            <Button
              key={path}
              component={NavLink}
              sx={{ color: 'inherit', mx: 1 }}
              activeStyle={{ color: '#29b194' }}
              to={path}
              startIcon={
                <Icon style={{ color: disabled ? '#b7bbba' : location.pathname.startsWith(path) ? '#29b194' : '' }} />
              }
              disabled={disabled}
            >
              {label}
            </Button>
          ))}
        </Box>
        <Box sx={{ px: 2, borderRight: '1px solid white', display: 'flex', flexDirection: 'column' }}>
          <Box component="span" sx={{ color: '#29b194' }}>
            {user?.role ? userLib.getUserRoleText(user.role) : '...'}
          </Box>
          <Box component="span">{user?.email ?? '...'}</Box>
        </Box>
        <IconButton aria-label="logout" size="large" color="inherit" onClick={handleLogout}>
          <LogoutIcon fontSize="inherit" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

type BaseNavLinkProps = NavLinkProps & {
  activeClassName?: string;
  activeStyle?: CSSProperties;
};

const NavLink = forwardRef<HTMLAnchorElement, BaseNavLinkProps>(({ activeClassName, activeStyle, ...props }, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={({ isActive }) => [props.className, isActive ? activeClassName : null].filter(Boolean).join(' ')}
    style={({ isActive }) => ({
      ...props.style,
      ...(isActive ? activeStyle : null),
    })}
  />
));
