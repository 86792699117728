import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ChangeUserDialog, userModel } from 'entities/user';

import { UserResponse } from 'shared/api';

type Props = {
  user: UserResponse | null;
};

export const ChangeUser = ({ user }: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { mutate: updateUser, isSuccess } = userModel.updateUserAsync()(dispatch);

  const handleOpenDialog = (e: any) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleCloseDialog = (e: any) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess) setIsOpen(false);
  }, [isSuccess]);

  if (!user) return null;

  return (
    <>
      <IconButton onClick={handleOpenDialog}>
        <Edit />
      </IconButton>
      <ChangeUserDialog user={user} isOpen={isOpen} onClose={handleCloseDialog} onSubmit={updateUser} />
    </>
  );
};
