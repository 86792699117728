import { Box, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

export const BaseLayout = ({ children }: PropsWithChildren) => <RootBox>{children}</RootBox>;

const RootBox = styled(Box)({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  backgroundColor: 'rgba(239, 245, 245, 1)',
});
