import { Dispatch, PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Disease, typicodeApi } from 'shared/api';

type TDiseaseState = {
  list: Disease[];
};

const initialState: TDiseaseState = {
  list: [],
};

export const diseasePetsModel = createSlice({
  name: 'diseases-pets',
  initialState,
  reducers: {
    setDiseases: (state, { payload }: PayloadAction<Disease[]>) => {
      state.list = payload;
    },
    addDiseases: (state, { payload }: PayloadAction<Disease>) => {
      state.list = [...state.list, payload];
    },
    updateDiseases: (state, { payload }: PayloadAction<Disease>) => {
      state.list = state.list.map((item) => (item._id === payload._id ? payload : item));
    },
  },
});

// export const {} = diseaseModel.actions;

// react-query actions (everything that async)
const ACTS_QUERY_KEY = 'diseases-pets';

export const getDiseasesAsync = () => (dispatch: Dispatch) =>
  useQuery<AxiosResponse<Disease[]>>(ACTS_QUERY_KEY, () => typicodeApi.diseasePets.getDiseases(), {
    onSuccess: ({ data }) => {
      dispatch(diseasePetsModel.actions.setDiseases(data));
    },
    onError: () => {
      toast.error('There was an error loading the list of diseases, try reloading the page!');
    },
    refetchOnWindowFocus: false,
  });

export const postNewDiseaseAsync = () => (dispatch: Dispatch) => {
  const postNewDisease = () =>
    useMutation(typicodeApi.diseasePets.postDisease, {
      onSuccess: ({ data }) => {
        dispatch(diseasePetsModel.actions.addDiseases(data));
        toast.success('Disease added successfully!');
      },
      onError: () => {
        toast.error('Error when adding a new disease. Try again!');
      },
    });

  return postNewDisease();
};

export const updateDiseaseAsync = () => (dispatch: Dispatch) => {
  const updateDisease = () =>
    useMutation(typicodeApi.diseasePets.patchDisease, {
      onSuccess: ({ data }) => {
        dispatch(diseasePetsModel.actions.updateDiseases(data));
        toast.success('The disease has been successfully changed!');
      },
      onError: () => {
        toast.error('Error when changing the disease. Try again!');
      },
    });

  return updateDisease();
};

// selectors
export const useDiseasesPets = () => useSelector((state: RootState) => state.diseasePets);
export const isDiseasesEmpty = (): boolean =>
  useSelector(
    createSelector(
      (state: RootState) => state.diseasePets.list,
      (diseasePets) => diseasePets.length === 0,
    ),
  );
export const reducerPets = diseasePetsModel.reducer;
