import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { userModel } from 'entities/user';

import { routesApp } from 'shared/config';

const validationSchema = yup.object({
  code: yup
    .string()
    .min(4, 'The code consists of 4 characters')
    .max(4, 'The code consists of 4 characters')
    .required('Code required field'),
});

const initialValues = { email: '', code: '' };

export const AuthSendCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate: sendEmail, isLoading } = userModel.authSendCodeAsync()(dispatch);
  const email = window.localStorage.getItem('email') ?? '';

  const handleBackStep = () => navigate(routesApp.sendEmail);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => sendEmail({ ...values, email }),
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
      <Paper sx={{ px: 3, py: 5 }}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Login Admin.Vaccine</DialogTitle>
          <DialogContent>
            <DialogContentText>An authorization code has been sent to your email: {email}.</DialogContentText>
            <DialogContentText>Enter the code in the field below.</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="code"
              name="code"
              label="Authorization code"
              type="text"
              fullWidth
              variant="standard"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBackStep}>Back</Button>
            <LoadingButton
              type="submit"
              loading={isLoading}
              variant="contained"
              disabled={formik.touched.code && Boolean(formik.errors.code)}
            >
              Sign in
            </LoadingButton>
          </DialogActions>
        </form>
      </Paper>
    </Box>
  );
};
