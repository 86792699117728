import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { userLib } from 'entities/user';

import { EUserRole, UserResponse } from 'shared/api';
import { SelectInput } from 'shared/ui';

const validationSchema = yup.object({
  role: yup.mixed<EUserRole>().oneOf(Object.values(EUserRole)).required('Обязательное поле'),
  descriptions: yup.string(),
});

const initialValues = {
  _id: '',
  role: EUserRole.User,
  descriptions: '',
};

type Props = {
  user: UserResponse;
  isOpen: boolean;
  onClose: (e: any) => void;
  onSubmit: (values: typeof initialValues) => void;
};

export const ChangeUserDialog = ({ user, isOpen, onClose, onSubmit }: Props) => {
  const formik = useFormik({
    initialValues: { role: user.role, _id: user._id, descriptions: user.descriptions },
    validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Change user</DialogTitle>
        <DialogContent>
          <SelectInput
            label="Роль"
            name="role"
            value={formik.values.role}
            menuItem={MenuItem}
            onChange={formik.setFieldValue}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            margin="dense"
            label="Descriptions"
            name="descriptions"
            value={formik.values.descriptions}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Отмена</Button>
          <Button type="submit" variant="contained">
            Изменить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const MenuItem = [
  { label: userLib.getUserRoleText(EUserRole.User), value: EUserRole.User },
  { label: userLib.getUserRoleText(EUserRole.Manager), value: EUserRole.Manager },
  { label: userLib.getUserRoleText(EUserRole.Admin), value: EUserRole.Admin },
];
