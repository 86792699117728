import axios from 'axios';

import { API_URL } from 'shared/config';

// const GLOBAL_HOST_URL = document.location.hostname === 'localhost' ? : '';

const apiInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export { apiInstance };
