import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

type Props = {
  children: ReactNode;
};

export const Query = ({ children }: Props) => (
  <QueryClientProvider client={queryClient} contextSharing>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
