import { AxiosPromise } from 'axios';

import { apiInstance } from './base';
import type { Country } from './models';

const BASE_URL = '/country';
// Authorization:
//       'Bearer ' +
//       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NGQwZmZiZTUyYTVmYTIwOWE5NjA1YWQiLCJpYXQiOjE2OTE0MTg1OTIsImV4cCI6MTY5MjAyMzM5Mn0.GX7ACRMHLIPefbvqSsepEWqc6QS1LGfmZZWmb2_c_Vs',
/**
 * Получение данных о добавленных Countryх
 */
export const getCountries = async (): AxiosPromise<Country[]> => apiInstance.get(`${BASE_URL}`);

/**
 * Добавление новой страны
 */
export const postProducts = async (data: Omit<Country, '_id' | 'vaccines'>): AxiosPromise<Country> =>
  apiInstance.post(`${BASE_URL}`, data, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });

// /**
//  * Изменение выбранного продукта пользователя
//  */
// // export const updateProduct = async (data: Omit<Product, 'files'>): AxiosPromise<Product> =>
// //   apiInstance.patch(`${BASE_URL}/product`, data);
// //   error_text: 'Ошибка при изменении продукта. Попробуйте еще раз',
//
// /**
//  * Изменение всех продуктов пользователя
//  */
// export const updateProducts = async (data: TUpdatedProducts[]): AxiosPromise =>
//   apiInstance.patch(`${BASE_URL}/products`, data);
//
// /**
//  * Добавление файлов для продукта пользователя по productId
//  */
// export const uploadFilesProducts = async ({
//   data,
//   productId,
// }: {
//   data: FormData;
//   productId: string;
// }): AxiosPromise<ProductFile> =>
//   apiInstance.post(`${BASE_URL}/files/${productId}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
//
// /**
//  * Удаление файла продукта пользователя
//  */
// export const deleteFileProducts = async (fileId: string): AxiosPromise =>
//   apiInstance.delete(`${BASE_URL}/files/${fileId}`);
