import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';

import { countryModel } from 'entities/country';
import { DiseaseDialog } from 'entities/disease';
import { userModel } from 'entities/user';

import { Disease, Vaccine } from 'shared/api';

type Props = {
  disease: Disease;
  isPets?: boolean;
  vaccineList: Vaccine[];
  diseaseList: Disease[];
  onUpdate: Function;
  isSuccessUpdate: boolean;
  isLoadingUpdate: boolean;
};
export const EditDisease = ({
  disease,
  vaccineList,
  diseaseList,
  onUpdate,
  isSuccessUpdate,
  isLoadingUpdate,
  isPets = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { list: countryList } = countryModel.useCountries();
  const { data } = userModel.useUser();

  const vaccineListMapped = vaccineList.map(({ name, _id, manufacturerCountry, manufacturer }) => ({
    label: name,
    labelContext: '',
    value: _id,
    manufacturerCountry,
    manufacturer,
  }));

  useEffect(() => {
    if (isSuccessUpdate) setIsOpen(false);
  }, [isSuccessUpdate]);

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} sx={{ alignSelf: 'end' }}>
        <Edit sx={{ color: '#29b194' }} />
      </IconButton>
      <DiseaseDialog
        title="Edit disease"
        selectedDisease={disease}
        isPets={isPets}
        vaccineList={vaccineListMapped}
        diseaseList={diseaseList}
        countriesList={countryList}
        userRole={data?.role}
        isOpen={isOpen}
        isLoading={isLoadingUpdate}
        onClose={() => setIsOpen(false)}
        onVerified={onUpdate}
        onSubmit={onUpdate}
      />
    </>
  );
};
