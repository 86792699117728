import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CreateDisease, EditDisease } from 'features/disease';

import { countryModel } from 'entities/country';
import { DiseaseTable, diseasePetsModel } from 'entities/disease';
import { vaccinePetsModel } from 'entities/vaccine';

import { LoadingProgress } from 'shared/ui';

export const DiseasePetsTab = () => {
  const dispatch = useDispatch();
  countryModel.getCountriesAsync()(dispatch);
  const { list: vaccinePetsList } = vaccinePetsModel.useVaccinesPets();
  const { list } = diseasePetsModel.useDiseasesPets();
  const { isFetching } = diseasePetsModel.getDiseasesAsync()(dispatch);
  const { mutate: createDisease, isSuccess, isLoading } = diseasePetsModel.postNewDiseaseAsync()(dispatch);
  const {
    mutate: updateDisease,
    isSuccess: isSuccessUpdate,
    isLoading: isLoadingUpdate,
  } = diseasePetsModel.updateDiseaseAsync()(dispatch);
  const isEmpty = diseasePetsModel.isDiseasesEmpty();

  const [filteringValue, setFilteringValue] = useState('');

  const filteringList = list.filter(
    (item) =>
      item.name.toLowerCase().includes(filteringValue.toLowerCase()) ||
      item.code.toLowerCase().includes(filteringValue.toLowerCase()),
  );

  const handleChangeFilter = (e: any) => setFilteringValue(e.target.value);
  const handleClearFilter = (e: any) => setFilteringValue('');

  if (isFetching) return <LoadingProgress />;

  return (
    <Box display="flex" flexDirection="column">
      <Paper>
        <Box display="flex" alignItems="center" p="8px 16px">
          <Typography variant="h6">Pets Disease control panel</Typography>
          <Box display="flex" alignItems="center" ml={3} mr="auto">
            <TextField
              sx={{ minWidth: '350px', width: '100%' }}
              placeholder="Enter name or code"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: filteringValue && (
                  <IconButton onClick={handleClearFilter}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              size="small"
              value={filteringValue}
              onChange={handleChangeFilter}
            />
            <Typography px={2} width="100%">
              {filteringList.length} disease
            </Typography>
          </Box>
          <CreateDisease
            onSubmit={createDisease}
            isSuccess={isSuccess}
            isLoading={isLoading}
            vaccineList={vaccinePetsList}
            diseaseList={list}
            isPets
          />
        </Box>
      </Paper>
      <Box mt={1}>
        {isEmpty && <Typography>No diseases pets added.</Typography>}
        {!isEmpty && (
          <DiseaseTable
            editRow={EditDisease}
            list={filteringList}
            onUpdate={updateDisease}
            vaccineList={vaccinePetsList}
            diseaseList={list}
            isSuccessUpdate={isSuccessUpdate}
            isLoadingUpdate={isLoadingUpdate}
            isPets
          />
        )}
      </Box>
    </Box>
  );
};
