import { ETypeVaccine, EUserRole } from 'shared/api';

export const getUserRoleText = (role: EUserRole | undefined): string => {
  if (!role) return 'Не задана';

  if (role === EUserRole.Admin) return 'Administrator';
  if (role === EUserRole.Manager) return 'Manager';

  return 'User';
};

export const getTypeVaccination = (type: ETypeVaccine) => {
  if (type === ETypeVaccine.Vaccination) return 'V';

  return 'R';
};
