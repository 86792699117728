import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type Props = {
  label: string;
  name: string;
  value: string | undefined;
  menuItem: Array<{ label: string; value: string }>;
  onChange: Function;
};

export const SelectInput = ({ label, name, value, menuItem, onChange }: Props) => (
  <FormControl fullWidth margin="dense">
    <InputLabel id="simple-select-label">{label}</InputLabel>
    <Select
      labelId="simple-select-label"
      id="simple-select"
      value={value}
      label={label}
      onChange={(e) => onChange(name, e.target.value)}
    >
      {menuItem.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
