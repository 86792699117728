import { AxiosPromise } from 'axios';

import { Disease } from 'shared/api';
import { apiInstance } from 'shared/api/typicode/base';

const BASE_URL = '/disease';

/**
 * Получение данных о добавленных болезнях
 */
export const getDiseases = async (): AxiosPromise<Disease[]> =>
  apiInstance.get(`${BASE_URL}`, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });

/**
 * Добавление новой болезни
 */
export const postDisease = async (data: Omit<Disease, '_id'>): AxiosPromise<Disease> =>
  apiInstance.post(`${BASE_URL}`, data, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });

/**
 * Обновление  болезни
 */
export const patchDisease = async (data: Disease): AxiosPromise<Disease> => {
  const { _id, ...datas } = data;

  return apiInstance.patch(`${BASE_URL}/${_id}`, datas, {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token') ?? ''}`,
    },
  });
};
