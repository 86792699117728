import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { ReactNode } from 'react';

import { Country } from 'shared/api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  label: string;
  name: string;
  options: Country[];
  values: string[];
  onChange: Function;
  multiple?: boolean;
  error?: boolean;
  helperText?: ReactNode;
};

export const AutocompleteCheckboxesCountry = ({
  label,
  name,
  options,
  values,
  onChange,
  error,
  helperText,
  multiple = false,
}: Props) => {
  return (
    <Autocomplete
      sx={{
        '& .MuiAutocomplete-endAdornment': {
          display: 'none',
        },
      }}
      fullWidth
      limitTags={5}
      multiple={multiple}
      options={options}
      value={options.filter((item) => values.includes(item.code))}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          <Box>
            <Typography>{option.name}</Typography>
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} name={name} margin="dense" error={error} helperText={helperText} />
      )}
      onChange={(_, v) =>
        onChange(
          name,
          (v as typeof options).map((i) => i.code),
        )
      }
    />
  );
};
