import { Box } from '@mui/material';

import { DiseasePetsTab } from 'widgets/disease';
import { VaccinePetsTab } from 'widgets/vaccine';

import { CountriesPetsTab } from 'features/countries';

import { NavTabs } from 'shared/ui';

const NAV_TABS = [
  {
    name: 'Vaccines',
    Content: () => <VaccinePetsTab />,
  },
  {
    name: 'Diseases',
    Content: () => <DiseasePetsTab />,
  },
  {
    name: 'Countries',
    Content: () => <CountriesPetsTab />,
  },
];

export const Pets = () => {
  return (
    <Box display="flex" flexDirection="column" flex={1} px={3}>
      <NavTabs tabs={NAV_TABS} />
    </Box>
  );
};
