import { Autocomplete, AutocompleteProps, Box, TextField } from '@mui/material';

import { Country } from 'shared/api';

type ACProps = {
  value?: string;
  label?: string;
  countries: Country[];
  onChange: (_: any, values: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
} & Partial<
  AutocompleteProps<
    {
      name: string;
      code: string;
      firstLetter: string;
    },
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >
>;

export const AutocompleteCountry = ({ value, label, countries, onChange, error, helperText, ...props }: ACProps) => {
  const options = countries.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  return (
    <Autocomplete
      fullWidth
      sx={{ pt: 1 }}
      options={options.sort((a, b) => -b.name.localeCompare(a.name))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option: any) => option.name}
      value={options.find((item) => item.name === value)}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.name} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder="Select country" label={label} error={error} helperText={helperText} />
      )}
      onChange={onChange}
      {...props}
    />
  );
};
