import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

import { userLib } from 'entities/user';

import { UserResponse } from 'shared/api';

type Props = {
  users: UserResponse[];
  openUser: (id: string) => void;
};
export const UsersTable = ({ users, openUser }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email user</TableCell>
            <TableCell align="right">Count of added peoples</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user._id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&.MuiTableRow-hover:hover': {
                  cursor: 'pointer',
                },
              }}
              hover
              onClick={() => openUser(user._id)}
            >
              <TableCell align="left">{user.email}</TableCell>
              <TableCell align="right">{user.peoples.length}</TableCell>
              <TableCell align="right">{userLib.getUserRoleText(user.role)}</TableCell>
              <TableCell align="right">{user.descriptions}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
