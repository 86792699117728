import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as StoreProvider } from 'react-redux';
import { ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ErrorBoundary, Query } from 'app/providers';

import { Routing } from 'pages';

import { theme } from 'shared/theme';

import { store } from './store';

const DEFAULT_TOAST_CONFIG: ToastOptions<{}> = {
  position: 'top-right',
};

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <StoreProvider store={store}>
          <CssBaseline />
          <Query>
            <Routing />
            <ToastContainer {...DEFAULT_TOAST_CONFIG} />
            <ReactQueryDevtools initialIsOpen={false} />
          </Query>
        </StoreProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
