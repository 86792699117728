import { Button } from '@mui/material';
import { useEffect, useState } from 'react';

import { countryModel } from 'entities/country';
import { DiseaseDialog } from 'entities/disease';
import { userModel } from 'entities/user';

import { Disease, Vaccine } from 'shared/api';

type Props = {
  onSubmit: Function;
  isPets?: boolean;
  vaccineList: Vaccine[];
  diseaseList: Disease[];
  isSuccess: boolean;
  isLoading: boolean;
};

export const CreateDisease = ({ onSubmit, isSuccess, isLoading, vaccineList, diseaseList, isPets = false }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { list: countryList } = countryModel.useCountries();
  const { data } = userModel.useUser();

  const vaccineListMapped = vaccineList.map(({ name, _id, manufacturerCountry, manufacturer }) => ({
    label: name,
    labelContext: '',
    value: _id,
    manufacturerCountry,
    manufacturer,
  }));

  useEffect(() => {
    if (isSuccess) setIsOpen(false);
  }, [isSuccess]);

  return (
    <>
      <Button variant="contained" onClick={() => setIsOpen(true)} sx={{ alignSelf: 'end' }}>
        Add disease
      </Button>
      <DiseaseDialog
        title="Add disease"
        isPets={isPets}
        vaccineList={vaccineListMapped}
        diseaseList={diseaseList}
        countriesList={countryList}
        userRole={data?.role}
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={() => setIsOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};
