import { ReactComponent as PharmaCompanies } from './building-hospital.svg';
import { ReactComponent as Analytics } from './chart.svg';
import { ReactComponent as Disease } from './face-mask.svg';
import { ReactComponent as Illnesses } from './heartbeat.svg';
import { ReactComponent as People } from './people.svg';
import { ReactComponent as Pet } from './pet.svg';
import { ReactComponent as ProgressHelp } from './progress-help.svg';
import { ReactComponent as Reactions } from './stethoscope.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Vaccine } from './vaccine.svg';

export { PharmaCompanies, Analytics, People, Users, Disease, Illnesses, Pet, Reactions, Vaccine, ProgressHelp };
