import { configureStore } from '@reduxjs/toolkit';

import { countryModel } from 'entities/country';
import { diseaseModel, diseasePetsModel } from 'entities/disease';
import { userModel } from 'entities/user';
import { vaccineModel, vaccinePetsModel } from 'entities/vaccine';

export const store = configureStore({
  reducer: {
    user: userModel.reducer,
    countries: countryModel.reducer,
    diseases: diseaseModel.reducer,
    vaccines: vaccineModel.reducer,
    vaccinesPets: vaccinePetsModel.reducerPets,
    diseasePets: diseasePetsModel.reducerPets,
  },
});
