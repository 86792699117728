import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ChangeUser } from 'features/user';

import { userLib, userModel } from 'entities/user';

import { LoadingProgress } from 'shared/ui';

export const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isFetching } = userModel.getUserByIdAsync(id as string)(dispatch);
  const { selectedUser } = userModel.useUser();

  const handleBack = () => {
    dispatch(userModel.setSelectedUser(null));
    navigate(-1);
  };

  if (isFetching) return <LoadingProgress />;

  return (
    <Box display="flex" flexDirection="column" flex={1} px={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button startIcon={<ArrowBack />} onClick={handleBack}>
          Back
        </Button>
      </Box>
      <Typography variant="h5" fontWeight="bold">
        User: {selectedUser?.email}
        <ChangeUser user={selectedUser} />
      </Typography>
      <Typography variant="subtitle1" fontWeight="bold" mb={3}>
        Role: {userLib.getUserRoleText(selectedUser?.role)}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {selectedUser?.descriptions && (
          <>
            <Typography variant="subtitle1" fontWeight="bolder">
              Comments about user
            </Typography>
            <Paper sx={{ p: 1 }}>{selectedUser?.descriptions}</Paper>
          </>
        )}
        {selectedUser?.peoples.length !== 0 && (
          <>
            <Typography variant="subtitle1" fontWeight="bolder" mt={3}>
              People List
            </Typography>
            <Box display="flex" gap={1}>
              {selectedUser?.peoples.map((people) => (
                <Paper sx={{ p: 1 }} key={people?._id}>
                  <Typography mb={2}>People name: {people.name}</Typography>
                  <Typography mb={2}>Selected diseases:</Typography>
                  <Box>
                    {people.listDisease.map((disease) => (
                      <Box key={disease._id} mb={3}>
                        <Typography mr={1}>Name: {disease.name}</Typography>
                        <Box key={disease._id} mb={1} display="flex" alignItems="center">
                          <Typography mr={1}>Rounds stamped:</Typography>
                          {people.listDoneVaccinations.length === 0
                            ? 'Vaccination has not started'
                            : people.listDoneVaccinations
                                .filter((item) => item.diseaseId === disease._id)
                                .map((item, index) => (
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ borderRadius: '50%', border: '2px solid green' }}
                                    mx={1}
                                    key={item._id}
                                    width={26}
                                    height={26}
                                  >
                                    {`${userLib.getTypeVaccination(item.type_vaccination)}${item.round}`}
                                  </Box>
                                ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
