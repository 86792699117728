import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CountriesTable, countryModel } from 'entities/country';
import { diseasePetsModel } from 'entities/disease';

import { LoadingProgress } from 'shared/ui';

export const CountriesPetsTab = () => {
  const dispatch = useDispatch();
  const { isLoading } = countryModel.getCountriesAsync()(dispatch);
  const { isFetching } = diseasePetsModel.getDiseasesAsync()(dispatch);
  const { list: listDisease } = diseasePetsModel.useDiseasesPets();
  const { list } = countryModel.useCountries();
  const [filteringValue, setFilteringValue] = useState('');

  const filteringList = list.filter(
    (item) =>
      item.name.toLowerCase().includes(filteringValue.toLowerCase()) ||
      item.code.toLowerCase().includes(filteringValue.toLowerCase()),
  );

  const handleChangeFilter = (e: any) => setFilteringValue(e.target.value);
  const handleClearFilter = (e: any) => setFilteringValue('');

  if (isLoading && isFetching) return <LoadingProgress />;

  return (
    <Box display="flex" flexDirection="column">
      <Paper>
        <Box display="flex" alignItems="center" p="8px 16px">
          <Typography variant="h6">Countries control panel</Typography>
          <Box display="flex" alignItems="center" ml={3} mr="auto">
            <TextField
              sx={{ minWidth: '350px', width: '100%' }}
              placeholder="Enter country name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: filteringValue && (
                  <IconButton onClick={handleClearFilter}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              size="small"
              value={filteringValue}
              onChange={handleChangeFilter}
            />
            <Typography px={2} width="100%">
              {filteringList.length} countries
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Box mt={1}>
        <CountriesTable list={filteringList} listDisease={listDisease} />
      </Box>
    </Box>
  );
};
