import { Dispatch, PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Vaccine, typicodeApi } from 'shared/api';

type TVaccinePetsState = {
  list: Vaccine[];
};

const initialState: TVaccinePetsState = {
  list: [],
};

export const vaccinePetsModel = createSlice({
  name: 'vaccinesPets',
  initialState,
  reducers: {
    setVaccines: (state, { payload }: PayloadAction<Vaccine[]>) => {
      state.list = payload;
    },
    addVaccines: (state, { payload }: PayloadAction<Vaccine>) => {
      state.list = [...state.list, payload];
    },
    updateDiseases: (state, { payload }: PayloadAction<Vaccine>) => {
      state.list = state.list.map((item) => (item._id === payload._id ? payload : item));
    },
  },
});

// export const {} = vaccineModel.actions;

// react-query actions (everything that async)
const ACTS_QUERY_KEY = 'vaccines-pets';

export const getVaccinesPetsAsync = () => (dispatch: Dispatch) =>
  useQuery<AxiosResponse<Vaccine[]>>(ACTS_QUERY_KEY, () => typicodeApi.vaccinePets.getVaccines(), {
    onSuccess: ({ data }) => {
      dispatch(vaccinePetsModel.actions.setVaccines(data));
    },
    onError: () => {
      toast.error('There was an error loading the list of vaccines, please try reloading the page!');
    },
    refetchOnWindowFocus: false,
  });

export const postNewVaccinePetsAsync = () => (dispatch: Dispatch) => {
  const postNewVaccine = () =>
    useMutation(typicodeApi.vaccinePets.postVaccine, {
      onSuccess: ({ data }) => {
        dispatch(vaccinePetsModel.actions.addVaccines(data));
        toast.success('The vaccine has been successfully added!');
      },
      onError: () => {
        toast.error('Error when adding a new vaccine. Try again!');
      },
    });

  return postNewVaccine();
};

export const updateVaccinePetsAsync = () => (dispatch: Dispatch) => {
  const updateVaccine = () =>
    useMutation(typicodeApi.vaccinePets.patchVaccine, {
      onSuccess: ({ data }) => {
        dispatch(vaccinePetsModel.actions.updateDiseases(data));
        toast.success('The vaccine has been successfully modified!');
      },
      onError: () => {
        toast.error('Error when changing the vaccine. Try again!');
      },
    });

  return updateVaccine();
};

// selectors
export const useVaccinesPets = () => useSelector((state: RootState) => state.vaccinesPets);
export const isVaccinesPetsEmpty = (): boolean =>
  useSelector(
    createSelector(
      (state: RootState) => state.vaccinesPets.list,
      (vaccines) => vaccines.length === 0,
    ),
  );

export const reducerPets = vaccinePetsModel.reducer;
