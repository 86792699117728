import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { countryModel } from 'entities/country';
import { userModel } from 'entities/user';
import { VaccineDialog, vaccineModel } from 'entities/vaccine';

import { Vaccine } from 'shared/api';

type Props = {
  vaccine: Vaccine;
  updateFn: Function;
  isSuccessUpdate: boolean;
  isLoadingUpdate: boolean;
};

export const EditVaccine = ({ vaccine, updateFn, isSuccessUpdate, isLoadingUpdate }: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { list: vaccineList } = vaccineModel.useVaccines();
  const { list } = countryModel.useCountries();
  const { data } = userModel.useUser();

  useEffect(() => {
    if (isSuccessUpdate) setIsOpen(false);
  }, [isSuccessUpdate]);

  return (
    <>
      <IconButton onClick={() => setIsOpen(true)} sx={{ alignSelf: 'end' }}>
        <Edit sx={{ color: '#29b194' }} />
      </IconButton>
      <VaccineDialog
        title="Add vaccine"
        isOpen={isOpen}
        selectedVaccine={vaccine}
        onClose={() => setIsOpen(false)}
        onSubmit={updateFn}
        onVerified={updateFn}
        isLoading={isLoadingUpdate}
        userRole={data?.role}
        vaccineList={vaccineList}
        countries={list}
      />
    </>
  );
};
