import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ChangeEvent } from 'react';

type Props = {
  titleGroup: string;
  name: string;
  value: string;
  row?: boolean;
  radios: Array<{ value: string; label: string }>;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
};

export const RadioButtonsGroup = ({ titleGroup, name, value, radios, onChange, row = false }: Props) => {
  return (
    <FormControl fullWidth margin="dense">
      <FormLabel id="radio-buttons-group-label">{titleGroup}</FormLabel>
      <RadioGroup row={row} aria-labelledby="radio-buttons-group-label" name={name} value={value} onChange={onChange}>
        {radios.map((radio) => (
          <FormControlLabel key={radio.label} value={radio.value} control={<Radio />} label={radio.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
