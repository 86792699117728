import { LoadingButton } from '@mui/lab';
import { Box, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { userModel } from 'entities/user';

const validationSchema = yup.object({
  email: yup.string().email('Please enter a valid email').required('Email required field'),
});

const initialValues = { email: '' };

export const AuthSendEmail = () => {
  const { mutate: sendEmail, isLoading } = userModel.authSendEmailAsync();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      sendEmail(values);
      window.localStorage.setItem('email', values.email);
    },
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
      <Paper sx={{ px: 3, py: 5 }}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Login Admin.Vaccine</DialogTitle>
          <DialogContent>
            <DialogContentText>An authorization code will be sent to the specified mail.</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              name="email"
              label="Email"
              type="text"
              fullWidth
              variant="standard"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              loading={isLoading}
              variant="contained"
              disabled={formik.touched.email && Boolean(formik.errors.email)}
            >
              Get code
            </LoadingButton>
          </DialogActions>
        </form>
      </Paper>
    </Box>
  );
};
