import { Component, ErrorInfo, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

type TState = {
  error: string;
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, TState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: '',
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: error.stack ?? '', hasError: true });
  }

  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <div
          className="d-flex error-message"
          style={{
            height: '100vh',
            color: '#8676de',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <p style={{ fontSize: '24px' }}>Сайт временно не доступен или произошла непредвиденная ошибка!</p>
          <p style={{ fontSize: '24px' }}>Попробуйте обновить страницу.</p>
          <span style={{ padding: '16px 0', maxWidth: 800 }}>StackTrace: {error}</span>
        </div>
      );
    }

    return children;
  }
}
