export type Country = {
  name: string;
  code: string;
};

// Права Юзера
export enum EUserRole {
  User = 'user',
  Admin = 'admin',
  Manager = 'manager',
}

export enum EUserSex {
  Female = 'female',
  Male = 'male',
}

export enum ETypeVaccine {
  Vaccination = 'vaccination', // вакцинация
  ReVaccination = 're_vaccination', // ревакцинация
}

export enum EDiseaseStatus {
  Basic = 'basic', // базовая (вроде как обязательная, но можно не ставить)
  Mandatory = 'mandatory', // обязательная
  Additional = 'additional', // дополнительаня
  Epidemic = 'epidemic', // эпидимиологическая
}

export const DiseaseStatusText = {
  [EDiseaseStatus.Basic]: 'Basic vaccination',
  [EDiseaseStatus.Mandatory]: 'Mandatory vaccination',
  [EDiseaseStatus.Additional]: 'Additional vaccination',
  [EDiseaseStatus.Epidemic]: 'Epidemic vaccination',
};

export type People = {
  _id: string;
  name: string;
  sex: EUserSex;
  birthday: string;
  country: string;
  is_owner: boolean;
  listDisease: Array<{
    _id: string;
    code: string;
    name: string;
  }>;
  listDoneVaccinations: Array<{
    _id: string;
    diseaseId: string;
    vaccineId: string;
    date: string;
    clinic: string;
    comments: string;
    reactions: string;
    type_vaccination: ETypeVaccine;
    round: number;
  }>;
};

// Модель Юзера
export type User = {
  email: string;
  role: EUserRole;
  token: string;
  expo_tokens: string[];
  peoples: People[];
  descriptions: string;
};

export type UserResponse = { _id: string } & Omit<User, 'token'>;

export enum ETypePets {
  Cat = 'cat',
  Dog = 'dog',
  All = 'all',
}

export type TDiseaseRound = {
  type_round: ENextRoundVariant;
  type_vaccination: ETypeVaccine;
  count_days?: number;
  count_days2?: number;
  count_years?: number;
  isPeriodicRevactination: boolean;
};

export type TDiseaseCountry = {
  countryCode: string;
  status: EDiseaseStatus;
  completed: boolean;
  age_shedule: Array<{
    start: number;
    end: number;
    rounds: TDiseaseRound[];
  }>;
};

export type Disease = {
  _id: string;
  name: string;
  name_ru: string;
  code: string;
  type_pets?: ETypePets;
  vaccine_list: string[];
  verified: boolean;
  isIncludePastDiseases?: boolean;
  descriptions?: string;
  descriptions_en?: string;
  notes?: string;
  countries: TDiseaseCountry[];
};

export enum ENextRoundVariant {
  AnyDays = 'any_days', // любой день
  DateOfBirth = 'from_the_birth', // от рождения человека
  FirstRound = 'first_round', //  от начала вакцинирования (от первого раунда)
  TimeOutRound = 'time_out_round',
}

export enum ENextRound {
  any_days = 'В любой день',
  from_the_birth = 'От даты рождения',
  first_round = 'От даты начала вакцинирования (от первого раунда)',
  time_out_round = 'От даты последней вакцинации',
}

export enum EVaccineType {
  alive = 'Live',
  inactivated = 'Inactivated',
  anatoxin = 'Anatoxin',
  molecular = 'Molecular',
}

export type TVaccineRound = {
  type_round: ENextRoundVariant;
  count_days?: number;
  count_days2?: number;
  type_vaccination: ETypeVaccine;
  count_years?: number;
  isPeriodicRevactination: boolean;
};

export type TVaccineAgeShedule = {
  start: number;
  end: number;
  rounds: TVaccineRound[];
};

export type Vaccine = {
  _id: string;
  name: string;
  name_ru: string;
  manufacturerCountry: string;
  manufacturer: string;
  availableCountries: string[];
  vaccine_type: EVaccineType;
  age_shedule: TVaccineAgeShedule[];
  verified: boolean;
  descriptions?: string;
  descriptions_en?: string;

  pharmacological_properties?: string;
  compound?: string;
  indications_for_use?: string;
  contraindications?: string;
  mode_of_application?: string;
  precautions_for_use?: string;
  carefully?: string;
  special_instructions?: string;
  side_effect?: string;
  use_during_pregnancy_and_breastfeeding?: string;
  interaction_with_other_drugs?: string;
  storage_conditions?: string;
  transportation_conditions?: string;

  notes?: string;
};
