import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CreateVaccine } from 'features/vaccine';
import { EditVaccine } from 'features/vaccine/ui/edit-vaccine';

import { countryModel } from 'entities/country';
import { diseaseModel } from 'entities/disease';
import { VaccineTable, vaccineModel } from 'entities/vaccine';

import { LoadingProgress } from 'shared/ui';

export const VaccineTab = () => {
  const dispatch = useDispatch();
  countryModel.getCountriesAsync()(dispatch);
  const { isFetching } = vaccineModel.getVaccinesAsync()(dispatch);
  const { isFetching: isFetchingDisease } = diseaseModel.getDiseasesAsync()(dispatch);
  const { mutate: createVaccine, isSuccess, isLoading } = vaccineModel.postNewVaccineAsync()(dispatch);
  const {
    mutate: updateVaccine,
    isSuccess: isSuccessUpdate,
    isLoading: isLoadingUpdate,
  } = vaccineModel.updateVaccineAsync()(dispatch);
  const isVaccinesEmpty = vaccineModel.isVaccinesEmpty();
  const { list } = vaccineModel.useVaccines();
  const { list: diseaseList } = diseaseModel.useDiseases();
  const [filteringValue, setFilteringValue] = useState('');

  const filteringList = list.filter((item) => item.name.toLowerCase().includes(filteringValue.toLowerCase()));

  const handleChangeFilter = (e: any) => setFilteringValue(e.target.value);
  const handleClearFilter = (e: any) => setFilteringValue('');

  if (isFetching && isFetchingDisease) return <LoadingProgress />;

  return (
    <Box display="flex" flexDirection="column">
      <Paper>
        <Box display="flex" alignItems="center" p="8px 16px">
          <Typography variant="h6">Human Vaccine control panel</Typography>
          <Box display="flex" alignItems="center" ml={3} mr="auto">
            <TextField
              sx={{ minWidth: '350px', width: '100%' }}
              placeholder="Enter the name of the vaccine"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: filteringValue && (
                  <IconButton onClick={handleClearFilter}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              size="small"
              value={filteringValue}
              onChange={handleChangeFilter}
            />
            <Typography px={2} width="100%">
              {filteringList.length} vaccines
            </Typography>
          </Box>
          <CreateVaccine onSubmit={createVaccine} isSuccess={isSuccess} isLoading={isLoading} />
        </Box>
      </Paper>
      <Box mt={1}>
        {isVaccinesEmpty && <Typography>No vaccine added.</Typography>}
        {!isVaccinesEmpty && (
          <VaccineTable
            list={filteringList}
            diseaseList={diseaseList}
            editRow={EditVaccine}
            updateFn={updateVaccine}
            isSuccessUpdate={isSuccessUpdate}
            isLoadingUpdate={isLoadingUpdate}
          />
        )}
      </Box>
    </Box>
  );
};
