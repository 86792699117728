import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EUserRole, User, UserResponse, typicodeApi } from 'shared/api';
import { routesApp } from 'shared/config';

type TUserState = {
  data: User | null;
  userList: UserResponse[];
  selectedUser: UserResponse | null;
};

const initialState: TUserState = {
  data: null,
  userList: [],
  selectedUser: null,
};

export const userModel = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.data = payload;
    },
    setSelectedUser: (state, { payload }: PayloadAction<UserResponse | null>) => {
      state.selectedUser = payload;
    },
    setUserList: (state, { payload }: PayloadAction<UserResponse[]>) => {
      state.userList = payload;
    },
    updateUserList: (state, { payload }: PayloadAction<UserResponse>) => {
      state.userList = state.userList.map((user) => (user._id === payload._id ? payload : user));
    },
  },
});

export const { setSelectedUser } = userModel.actions;

// react-query actions (everything that async)
const ACTS_QUERY_KEY = 'user';
export const getUserAsync = () => (dispatch: Dispatch) =>
  useQuery<AxiosResponse<User>>(ACTS_QUERY_KEY, () => typicodeApi.user.getUser(), {
    onSuccess: ({ data }) => {
      dispatch(userModel.actions.setUser(data));
    },
    onError: () => {},
    refetchOnWindowFocus: false,
  });

const ACTS_QUERY_KEY2 = 'userById';
export const getUserByIdAsync = (id: string) => (dispatch: Dispatch) =>
  useQuery<AxiosResponse<UserResponse>>(ACTS_QUERY_KEY2, () => typicodeApi.user.getUserById(id), {
    onSuccess: ({ data }) => {
      dispatch(userModel.actions.setSelectedUser(data));
    },
    onError: () => {},
    refetchOnWindowFocus: false,
  });

const ACTS_QUERY_KEY3 = 'users';
export const getUsersAsync = () => (dispatch: Dispatch) =>
  useQuery<AxiosResponse<UserResponse[]>>(ACTS_QUERY_KEY3, () => typicodeApi.user.getUsers(), {
    onSuccess: ({ data }) => {
      dispatch(userModel.actions.setUserList(data));
    },
    onError: () => {},
    refetchOnWindowFocus: false,
  });

export const updateUserAsync = () => (dispatch: Dispatch) => {
  const updateUser = () =>
    useMutation(typicodeApi.user.updateUserById, {
      onSuccess: ({ data }) => {
        dispatch(userModel.actions.updateUserList(data));
        dispatch(userModel.actions.setSelectedUser(data));
        toast.success('Account updated successfully!');
      },
      onError: () => {
        toast.error('Error while updating. Try again!');
      },
    });

  return updateUser();
};

export const authSendEmailAsync = () => {
  const navigate = useNavigate();
  const authSendEmail = () =>
    useMutation(typicodeApi.user.authSendEmail, {
      onSuccess: () => {
        toast.success('Code sent successfully!');
        navigate(routesApp.sendCode);
      },
      onError: () => {
        toast.error('Ошибка при отправке кода авторизации. Попробуйте еще раз!');
      },
    });

  return authSendEmail();
};

export const authSendCodeAsync = () => (dispatch: Dispatch) => {
  const navigate = useNavigate();
  const authSendCode = () =>
    useMutation(typicodeApi.user.authSendCode, {
      onSuccess: ({ data }) => {
        if (data.role === EUserRole.User) {
          toast.error('Access to the service is prohibited! You may not have sufficient access rights.');
          return;
        }

        toast.success('You are logged in!');
        window.localStorage.removeItem('email');
        window.localStorage.setItem('token', data.token);
        dispatch(userModel.actions.setUser(data));
        navigate(routesApp.peoples);
      },
      onError: () => {
        toast.error('Error sending authorization code. Try again!');
      },
    });

  return authSendCode();
};

// selectors

export const useUser = () => useSelector((state: RootState) => state.user);

export const reducer = userModel.reducer;
