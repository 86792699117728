import { Route, Routes } from 'react-router';

import { User } from 'pages/user';

import { AuthGuard } from 'features/auth';

import { routesApp } from 'shared/config';
import { BaseLayout, Header } from 'shared/ui';

import { AuthSendCode } from './auth-send-code';
import { AuthSendEmail } from './auth-send-email';
import { Main } from './main';
import { Pets } from './pets';
import { Users } from './users';

export const Routing = () => {
  return (
    <BaseLayout>
      <Routes>
        <Route path={routesApp.sendEmail} element={<AuthSendEmail />} />
        <Route path={routesApp.sendCode} element={<AuthSendCode />} />
        <Route
          path={routesApp.peoples}
          element={
            <AuthGuard>
              <>
                <Header />
                <Main />
              </>
            </AuthGuard>
          }
        />
        <Route
          path={routesApp.pets}
          element={
            <AuthGuard>
              <>
                <Header />
                <Pets />
              </>
            </AuthGuard>
          }
        />
        <Route
          path={routesApp.users}
          element={
            <AuthGuard>
              <>
                <Header />
                <Users />
              </>
            </AuthGuard>
          }
        />
        <Route
          path={`${routesApp.users}/:id`}
          element={
            <AuthGuard>
              <>
                <Header />
                <User />
              </>
            </AuthGuard>
          }
        />
      </Routes>
    </BaseLayout>
  );
};
