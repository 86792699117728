import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { spawn } from 'child_process';
import { ReactNode } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  label: string;
  name: string;
  options: Array<{
    label: string;
    value: string;
    labelContext?: string;
    manufacturerCountry: string;
    manufacturer: string;
  }>;
  values: string[];
  onChange: Function;
  multiple?: boolean;
  error?: boolean;
  helperText?: ReactNode;
};

export const AutocompleteCheckboxes = ({
  label,
  name,
  options,
  values,
  onChange,
  error,
  helperText,
  multiple = false,
}: Props) => {
  return (
    <Autocomplete
      sx={{
        '& .MuiAutocomplete-endAdornment': {
          display: 'none',
        },
      }}
      fullWidth
      limitTags={5}
      multiple={multiple}
      options={options}
      value={options.filter((item) => values.includes(item.value))}
      disableCloseOnSelect
      getOptionLabel={(option) => `${option.label} (${option.manufacturerCountry}, ${option.manufacturer})`}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.value}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          <Box>
            <Typography>{option.label}</Typography>
            {option.labelContext && (
              <Typography sx={{ color: 'gray', px: 1 }}>
                {option.labelContext} ({option.manufacturerCountry}, {option.manufacturer})
              </Typography>
            )}
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} name={name} margin="dense" error={error} helperText={helperText} />
      )}
      onChange={(_, v) => {
        onChange(
          name,
          (v as typeof options).map((i) => i.value),
        );
      }}
    />
  );
};
