import { Autocomplete, Box, TextField } from '@mui/material';

type ACProps = {
  label: string;
  value: string;
  list: Array<{ name: string; code: string }>;
  sortVariant?: 'name' | 'code';
  onChange: Function;
};
export const AutocompleteNameWithCode = ({ label, value, list, onChange, sortVariant = 'name' }: ACProps) => {
  const options = list.map((option) => {
    const firstLetter = option[sortVariant][0].toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  return (
    <>
      <Autocomplete
        sx={{ pt: 1 }}
        fullWidth
        value={options.find((item) => item.name === value)}
        options={options.sort((a, b) => -b[sortVariant].localeCompare(a[sortVariant]))}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box component="li" {...props} sx={{ width: '100%' }}>
            <Box component="span">{option.name} </Box>
            <Box component="span" sx={{ color: 'gray', px: 1 }}>
              {option.code}
            </Box>
          </Box>
        )}
        renderInput={(params) => <TextField {...params} label={label} margin="dense" />}
        onChange={(_, v) => {
          void onChange('name', v?.name ?? '');
          void onChange('code', v?.code ?? '');
        }}
      />
    </>
  );
};
