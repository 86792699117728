import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { userModel } from 'entities/user';

import { routesApp } from 'shared/config';

// import { useAuth,  } from '../hooks';

const AUTH_ROUTES = [routesApp.sendEmail, routesApp.sendCode];
// const AVAILABLE_ROUTES = [routes.home];

type Props = { children: JSX.Element };

export const AuthGuard = ({ children }: Props) => {
  const dispatch = useDispatch();
  userModel.getUserAsync()(dispatch);

  const isAuthorized = window.localStorage.getItem('token');

  const { pathname } = useLocation();

  if (isAuthorized) {
    if (AUTH_ROUTES.includes(pathname)) {
      return <Navigate to={routesApp.peoples} replace />;
    }

    return children;
  }

  if (![...AUTH_ROUTES].includes(pathname)) {
    return <Navigate to={routesApp.sendEmail} replace />;
  }

  return children;
};
