import { ReportGmailerrorred, TaskAlt } from '@mui/icons-material';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactElement } from 'react';

import { countryLib, countryModel } from 'entities/country';

import { Disease, Vaccine } from 'shared/api';
import { ProgressHelp } from 'shared/assets/svg';

type Props = {
  list: Vaccine[];
  editRow: (props: any) => ReactElement;
  updateFn: Function;
  diseaseList: Disease[];
  isSuccessUpdate: boolean;
  isLoadingUpdate: boolean;
};
export const VaccineTable = ({
  list,
  editRow: EditRow,
  diseaseList,
  updateFn,
  isSuccessUpdate,
  isLoadingUpdate,
}: Props) => {
  const { list: countryList } = countryModel.useCountries();

  const countryVaccinelist = list.sort((a, b) => a.name.localeCompare(b.name));

  const getDiseaseList = (vaccineId: string): Array<{ name: string; verified: boolean }> => {
    const usedVaccine = diseaseList.filter(({ vaccine_list }) => vaccine_list.includes(vaccineId));

    if (usedVaccine.length === 0) return [{ name: 'Not used', verified: false }];

    return usedVaccine.map(({ name, verified }) => ({ name, verified }));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 750 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <ProgressHelp />
              </TableCell>
              <TableCell width={600}>Trade name</TableCell>
              <TableCell>Manufacturing / Country </TableCell>
              <TableCell width={80}>Age</TableCell>
              <TableCell>Countries certified</TableCell>
              <TableCell width={460}>Used in Diseases</TableCell>
              <TableCell align="right">Controls</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {countryVaccinelist.map((row) => {
              return (
                <TableRow key={row._id}>
                  <TableCell align="center">
                    {row.verified ? <TaskAlt color="success" /> : <ReportGmailerrorred color="warning" />}
                  </TableCell>
                  <TableCell component="th" scope="row" sx={{ verticalAlign: 'top' }}>
                    <Box mb={1}>{row.name}</Box>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Box mb={1}>{row.manufacturer}</Box>
                    <Box>{row.manufacturerCountry}</Box>
                  </TableCell>
                  <TableCell>
                    {row.age_shedule[0]?.start}-{row.age_shedule.at(-1)?.end}
                  </TableCell>
                  <TableCell>
                    {countryLib.getCountriesListNamesByCode(countryList, row.availableCountries).length}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    <Box display="flex" flexWrap="wrap">
                      {getDiseaseList(row._id).map((disease) => (
                        <Box
                          key={disease?.name}
                          component="span"
                          p={0.5}
                          m={0.25}
                          sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            borderRadius: '4px',
                            whiteSpace: 'nowrap',
                            color: disease.verified ? '#29b194' : 'inherit',
                          }}
                        >
                          {disease?.name}
                        </Box>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <EditRow
                      vaccine={row}
                      updateFn={updateFn}
                      isSuccessUpdate={isSuccessUpdate}
                      isLoadingUpdate={isLoadingUpdate}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
